import { useQuery } from '@tanstack/vue-query'
import { QueryOptions } from '~/src/types/QueryOptions'
import { paths } from '~/src/api/schema'
import { BalanceCollection } from '~/src/types/Balance'

const GET_BALANCES = '/api/balances'

export function getBalances ({ params, query }: QueryOptions<paths[typeof GET_BALANCES]['get']>) {
  return useQuery({
    ...query,
    queryKey: [
      GET_BALANCES
    ],
    queryFn: async ({ signal }) => {
      const client = useApi()
      const { data } = await client.GET(GET_BALANCES, {
        params,
        signal
      })
      return data as BalanceCollection
    }
  })
}
